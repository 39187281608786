export enum AppModules {
  login,
  profile,
}

export enum AppNav {
  profile = '/profile',
  login = '/login',
  register = '/register',
}
